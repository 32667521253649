//.. local run --development 
const company = {
    name: 'Shakers',
    logo:'shakers_logo.png'
};

const dev_app = {
    API_URL: "https://admin.wizardcomm.in/serviceb2c/api/"/* ,
    COMPANY_URL: "https://devb2c.wizardcomm.in/?companyid=shakersdaily",
    IMG_URL: "https://devb2c.wizardcomm.in/images/" */
};

const qa_app = {
    API_URL: "https://ecommerceqa.wizardcomm.net/api/"/* ,
    COMPANY_URL: "ecommerceqa.wizardcomm.net",
    IMG_URL: "https://ecommerceqa.wizardcomm.net/images/" */
};

/* for shakers */
const stage_app = {
    API_URL: "https://stageadmin.uvanij.com/API/api/"/* ,
    COMPANY_URL: "https://stage.uvanij.com/?company=shakersdaily",
    IMG_URL: "https://stage.uvanij.com/images/shakersdaily/" */
};

/* for debanjana */
const debanjana_app = {
    API_URL: "https://stageadmin.uvanij.com/API/api/"/* ,
    COMPANY_URL: "https://debanjanaboutique.uvanij.com/",
    IMG_URL: "https://debanjanaboutique.uvanij.com/images/" */
};

/* for suswadu */
const suswaducaterer_app = {
    API_URL: "https://stageadmin.uvanij.com/API/api/"/* ,
    COMPANY_URL: "https://suswaducaterer.uvanij.com/",
    IMG_URL: "https://suswaducaterer.uvanij.com/images/" */
};

const prod_app = {
    API_URL: "https://admin.uvanij.com/API/api/"/* ,
    COMPANY_URL: "shakersdaily.uvanij.com",
    IMG_URL: "https://shakersdaily.uvanij.com/images/" */
};

let configured = {company};

if(process.env.NODE_ENV === 'development'){
    //.. local dev env:: preconfigured
    configured = {...configured, app:stage_app, cacheTimeOut:0}; // CHANGE FOR LOCAL DEVELOPMENT
}else{
    //.. get app related details from .env file
    configured = {...configured, app:{
        API_URL: process.env.REACT_APP_API_URL/* ,
        IMG_URL: process.env.REACT_APP_IMG_URL */
    },
    cacheTimeOut:parseInt(process.env.REACT_APP_CACHE_INTERVAL) };
}

export const config = configured; 
