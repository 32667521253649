import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { getCategoryList, selectCategory,selectsubCategory,selectsubsubCategory, setMenuState } from '../../actions/CategoryAction';
import Category  from './Category';
import { Nav } from 'react-bootstrap';
import { filterByCategory } from '../../actions/ProductsAction';
import Scroller from '../Scroller';

const CategoryMenu = (props) => {

    const {companyid, getCategoryList } = props;

    const navigate = useNavigate();
    const location = useLocation();
    // eslint-disable-next-line
    useEffect(() => { 
        if(companyid && companyid !== '') {
            getCategoryList(companyid)
          }
    }, [companyid]);
    
    useEffect(() => {
        props.selectCategory(location.pathname.replace('/category/',''));
    },[location.pathname]);
    
    const onCategorySelect = (eventKey) => {
		props.selectCategory(eventKey.split(' ')[0].split(':')[1]);
		props.selectsubsubCategory(eventKey);
		if(props.isMobileMenu) {
            props.setMenuState(false)
        }
        navigate(`/category/${eventKey.split(' ')[0].split(':')[1]}`);
		console.log(eventKey);
    }
    const catMenuData = props.CategoryList.map((category, key) => (<Category category={category} key={key} keyValue={key} />));
    
    //.. check config
    //const scroll_nav={type:"fa",left:"fa fa-chevron-circle-left arrow-stylecontrol", right:"fa fa-chevron-circle-right arrow-stylecontrol"};
    
    return (
        <div className={props.menuState ? "menu-items" : "hideclassmain"}>
            <div className="categories-section">
               
			   <Nav justify variant="pills" className="nav-box" onSelect={onCategorySelect} >
			   
                    {(props.isMobileMenu)?(catMenuData):(props.scroll_range && props.scroll_nav && catMenuData.length>0)?(<Scroller data={catMenuData} range={props.scroll_range} nav={props.scroll_nav} />):null}
			   
			   </Nav>
			   
                
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    companyid:state.HomeData.companyid,
    CategoryList: state.CategoryData.categoryList,
    menuState: state.CategoryData.menuState,
    isMobileMenu: state.CategoryData.isMobileMenu,
	scroll_range: state.HomeData.scroll_range,
	scroll_nav: state.HomeData.scroll_nav
})

export default connect(mapStateToProps, {getCategoryList, selectCategory,selectsubCategory,selectsubsubCategory, filterByCategory, setMenuState})(CategoryMenu);