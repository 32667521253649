//import '../utils/wdyr';

import React, { useState, useEffect, lazy } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';

import ThemeSwitcher from 'react-css-vars';
//import '../assets/css/custom.scss';

import 'bootstrap/dist/css/bootstrap.css';

import "../assets/css/generic.css";



//.. TODO theme implementation on company basis
//import "../assets/css/shakersdaily.css";
//import "../assets/css/debanjana.css";
//import "../assets/css/suswadu.css";

import Loader from './Loader';

import { useWindowResizeListener } from '../utils/useHooks';
import { setMenuState, setMobileState } from '../actions/CategoryAction';
import { getCompanyId, getConfigInfo } from '../actions/HomeAction';
import HomeContainer from './HomeContainer';
import Products from '../components/Products';
import PinModal from '../components/PinModal';
import ProductDetails from '../components/ProductDetails';
import CategoryContainer from './CategoryContainer';
import SearchPanel from './SearchPanel';
import FeaturePanel from './FeaturePanel';
import VariantModal from "../components/VariantModal";
import CheckOut from './CheckOut';
import CartPreviewModal from './CartPreviewModal';
import NotFound from './NotFound';

import ScrollToTop from '../components/Scroll';

/* const HomeContainer = lazy(() => import('./HomeContainer'));
const Products = lazy(() => import('../components/Products'));
const PinModal = lazy(() => import('../components/PinModal'));
const ProductDetails = lazy(() => import('../components/ProductDetails'));
const CategoryContainer = lazy(() => import('./CategoryContainer'));
const SearchPanel = lazy(() => import('./SearchPanel'));
const VariantModal = lazy(() => import('../components/VariantModal'));
const CheckOut = lazy(() => import('./CheckOut'));
const CartPreview = lazy(() => import('./CartPreview'));
const NotFound = lazy(() => import('./NotFound')); */

import { Global, isDev } from '../utils/Env';
import {addStyle, getThemeColors} from '../utils/CommonUtil'; 


var styles;
const loadCSSforDevEnv = cssName => {
    if (isDev()) {
        import(/* webpackMode: "eager" */ `../assets/css/${cssName}.css`).then(imported => styles = imported.default);
    }
}

const App = props => {

    const { theme, getConfigInfo } = props;
    const [themePalette, setThemePalette] = useState([]);

    Global.callback.getConfigInfo_onComplete = () => {
        if(theme.name === 'random'){
            //getRandomTheme();
            //console.log("themePalatte ", themePalette);
        }else{   
            if(!isDev()){
                addStyle(`./static/css/${theme.name}.css`);
            }else{
                loadCSSforDevEnv(theme.name)
            }
        }
        props.getCompanyId();
    }

    const getRandomTheme = async () => {
        const randomTheme = await getThemeColors();
      
        if (randomTheme.data) {
            setThemePalette(randomTheme.data.result);
          console.log(`Got ${Object.entries(themePalette).length} themes -> ${themePalette}`)
        }
      }

    //.. default theme
    const [defaultTheme, setDefaultTheme] = useState({
        themecolor: '',
        mobileCardHeight: '',
        desktopCardHeight: ''
    });

    const { width } = useWindowResizeListener();
    const { setMenuState, setMobileState } = props;

    //.. on resize menu observer
    useEffect(() => {
        if (width < 768) {
            setMenuState(false);
            setMobileState(true);
        } else {
            setMenuState(true);
            setMobileState(false);
        }
    }, [width, setMenuState, setMobileState]);

    //.. update theme
    useEffect(() => {
        let { themecolor, mobileCardHeight, desktopCardHeight } = defaultTheme;
         setDefaultTheme({
            themecolor: theme && theme.color ? theme.color : themecolor,
            mobileCardHeight: theme && theme.mobile_card_height ? theme.mobile_card_height : mobileCardHeight,
            desktopCardHeight: theme && theme.desktop_card_height ? theme.desktop_card_height : desktopCardHeight
        });
		console.log(defaultTheme, themePalette);
    }, [theme, themePalette]);

    //... initiate load config
    useEffect(() => {
        getConfigInfo();
    }, [])

    return (
        <ThemeSwitcher theme={defaultTheme} elementId="root">
            <Container fluid="true">
                <ScrollToTop>
                    <Routes>
                        <Route path="/" element={<HomeContainer />} >
                            <Route path="products" element={<Outlet />}>
                                <Route path="/" element={<Products />} />
                                <Route path="/:slug" element={<ProductDetails />} />
                                <Route path="*" element={<NotFound />} />
                            </Route>
                            <Route path="/" element={<FeaturePanel />} />
                            <Route path="search" element={<SearchPanel />} />
                            <Route path="category" element={<Outlet />} >
                                <Route path="/" element={<Outlet />} />
                                <Route path="/:catid" element={<CategoryContainer />} />
                                <Route path="/:catid/:subcatid" element={<CategoryContainer />} />
                                <Route path="*" element={<NotFound />} />
                            </Route>
                            <Route path="*" element={<NotFound />} />
                        </Route>
                        <Route path="/checkout" element={<CheckOut />} />
                    </Routes>
                    <PinModal />
                    <VariantModal />
                    <CartPreviewModal />
                    <ToastContainer autoClose={false} />
                    <Loader />
                </ScrollToTop>
            </Container>
        </ThemeSwitcher>
    )
}

const mapStateToProps = state => ({
    theme: state.HomeData.theme
})

export default connect(mapStateToProps, { setMenuState, setMobileState, getCompanyId, getConfigInfo })(App);
