import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';

//import ThemeSwitcher from 'react-css-vars';

import Header from '../components/header/Header';
import Footer from '../components/Footer';
import Brands from '../components/Brands';
import Notification from '../components/Notification';

import { getProductsList } from '../actions/ProductsAction';
import { setCheckOutCartState } from '../actions/CartAction';


const HomeContainer = props => {

  const { companyid, setCheckOutCartState, getProductsList } = props;
  //.. initial call for company and app setup

  useEffect(() => {
    setCheckOutCartState(false);
  }, []);

  useEffect(() => {
    if (companyid && companyid !== '') {
      getProductsList(companyid);
    }
  }, [companyid]);


  return (
    <React.Fragment>
      <header id='header'>
        <Notification />
		    <Header />
      </header>
      <div className={props.deliverablePin ? "full-Pagesection-notification-removed full-Pagesection" : "full-Pagesection"}>
        <Brands />
        <Outlet />
      </div>
      <Footer />
    </React.Fragment>
  );

}

const mapStateToProps = state => ({
  companyid: state.HomeData.companyid,
  productsList: state.ProductsData.productsList,
  mobileState: state.CategoryData.isMobileMenu,
  notification: state.UserData.notification,
  deliverablePin: state.LocationData.deliverablePin
})

export default connect(mapStateToProps, { getProductsList, setCheckOutCartState })(HomeContainer);